import React from 'react';
import HappyHomeLogo from '../../../images/Logo/HappyHomeLogo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <a href="/" className="footer-logo-link">
          <img src={HappyHomeLogo} alt="Logo" className="footer-logo" />
        </a>
      </div>
      <div className="footer-column">
        <p className="footer-column-title">REDES SOCIALES</p>
        <a target="_blank" href="https://www.instagram.com/help.happyhome">Instagram</a>
        <a target="_blank" href="https://www.instagram.com/help.happyhome">Facebook</a>
        <a target="_blank" href="https://www.tiktok.com/@help.happy.home">Tik Tok</a>
        <a target="_blank" href="https://www.linkedin.com/in/happy-home">Linkedin</a>
      </div>
      <div className="footer-column">
        <p className="footer-column-title">CONTACTO</p>
        <a target="_blank" href="tel:1160489640">11 6048 9640</a>
        <a target="_blank" href="https://wa.link/xon84d">Whatsapp</a>
        <a target="_blank" href="mailto:info@happyhome.com.ar">info@happyhome.com.ar</a>
      </div>
      <div className="footer-column">
        <p className="footer-column-title">NOSOTROS</p>
        <a href="/contact">Contacto</a>
        <a href="/memberships">Membresias</a>
        <a href="/faqs">Preguntas frecuentes</a>
        <a href="/terms">Términos y condiciones</a>
        <a href="/privacy-policy">Políticas de privacidad</a>
      </div>
    </footer>
  );
};

export default Footer;
