import React, { useState } from 'react';
import defaultProfileImage from '../../../images/ProfilePicture/HH_ProfileDefaultImage.png';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerCard = ({ worker }) => {
  const [loading, setLoading] = useState(true);
  const workerAge = worker.date_of_birth ? `${new Date().getFullYear() - new Date(worker.date_of_birth).getFullYear()} años` : '';

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="worker-card">
      <div className="worker-container">
        <div className="left-container">
          <div className="image-container">
            {loading && <LoadingSpinner />}
            <img
              src={worker.user.profile_image || defaultProfileImage}
              alt="Worker Profile Image"
              className={`worker-profile-image ${loading ? 'hidden' : ''}`}
              onLoad={handleImageLoad}
            />
          </div>
        </div>
        
        <div className="right-container">
          <div className="worker-top-container">
            <div className="worker-name">
              <p><strong>{worker.user.first_name}</strong>, {workerAge}</p>
            </div>
          </div>
        
          {worker.location && (
            <p className="worker-detail-line">{worker.location.municipality.name}, {worker.location.province.name}</p>
          )}
          
          <div className="worker-about">
            <div className="worker-introduction">
              <p>{worker.introduction ? worker.introduction.substring(0, 100) : ''}{worker.introduction && worker.introduction.length > 100 && '...'}</p>
            </div>

            <div className="worker-services">
              {worker.services.map(service => (
                <p className="worker-service" key={service.id}>{service.title_display}</p>
              ))}
            </div>

            <div className="worker-work-arrangements">
              {worker.work_arrangements.map((work_arrangement, index) => (
                <p className="worker-work-arrangement" key={work_arrangement.id}>
                  {work_arrangement.value_display}
                  {index < worker.work_arrangements.length - 1 && ', '}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerCard;
