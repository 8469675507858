import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthProvider';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const MembershipPaymentMethod = ({ membership, onClose }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
  const navigate = useNavigate();

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    redirectToPaymentPage(paymentMethod);
  };

  const redirectToPaymentPage = (paymentMethod) => {
    if (paymentMethod === 'bank_transfer') {
      navigate(`/membership-type/${membership.id}/bank-transfer/payment`);
    }
    // Puedes agregar más condiciones para otros métodos de pago en el futuro
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Método de pago</h3>
            <div className="popup-intro-description">
              <p>Pagarás la <strong>membresía {membership.name}</strong> por un valor de <strong>${membership.price}</strong> con una duración de 30 días a partir del pago. Recibiras todos los detalles mediante correo electrónico.</p>
            </div>
          </div>
          <div className="payment-method-select-container">
            <form className="popup-form" onSubmit={handleFormSubmit}>
              <div className="popup-form-rows">
                <div className="popup-select-form-row" onClick={() => handlePaymentMethodChange('bank_transfer')}>
                  <div className="popup-form-label">
                    <label>Transferencia Bancaria</label>
                  </div>
                  <div className="popup-form-icon">
                    <img src={paymentMethod === 'bank_transfer' ? checkIcon : crossIcon} alt="Icon" className="icon-svg" />
                  </div>
                </div>
                
                {/* Puedes agregar más métodos de pago aquí */}
              </div>
              <div className="buttons-container">
                <button type="submit" className="primary-btn">Continuar al pago</button>
                <button type="button" className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipPaymentMethod;
